import { useContext, useRef, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios"
import { AuthContext } from "../contexts/AuthContext";
import { API } from "../api"
import searchvideo from "../videos/searchvideo.mp4"


export function Navbar() {
  const videoEl = useRef(null);
  const { user, logout } = useContext(AuthContext)
  const navigate = useNavigate()

  function handleSubmit() {
    axios.post(API.auth.logout)
      .then(res => {
        logout()
        navigate('/login')
      })
  }
  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6">
      <div className="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
        <div className="flex justify-start lg:w-0 lg:flex-1">
          <a href="/">
            <video width="200" height="200" loop
              muted
              autoplay
              playsinline
              src={searchvideo}
              ref={videoEl}>
              {/* <source src={searchvideo} type="video/mp4" /> */}

            </video>
          </a>
        </div>
        <div className="-my-2 -mr-2 md:hidden">
        </div>


        <a href="/" className="text-base font-medium text-gray-500 hover:text-gray-900">
          Find a Job
        </a>
        <a href="/create-job" className="text-base font-medium text-gray-500 hover:text-gray-900">
          Add a Job
        </a>
        <a href="/my-jobs" className="text-base font-medium text-gray-500 hover:text-gray-900">
          My jobs
        </a>

        {user ? (
          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            <a className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900" onClick={handleSubmit}>
              logout
            </a>
          </div>
        ) : (


          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            <a href="/login" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
              Login
            </a>
            <a
              href="/signup"
              className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
            >
              Sign up
            </a>
          </div>
        )}
      </div>
    </div >
  )
}


